import React from 'react'
import { graphql, navigate } from 'gatsby'
import styled from '@emotion/styled'

import { isLoggedIn } from '../services/auth'
import { isBrowser } from '../utils/utils'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { mq, rem } from '../utils/styles'

const Container = styled.article`
  font-size: ${rem(15)};
  margin-top: 60px;

  ${mq.lessThan('mobile')} {
    font-size: ${rem(13)};
    margin-top: 30px;
  }
`

const Header = styled.header`
  .title {
    font-size: ${rem(25)};
    font-weight: 600;
    line-height: 1.3;

    ${mq.lessThan('mobile')} {
      font-size: ${rem(16)};
      margin-top: 30px;
    }
  }

  .description {
    font-size: ${rem(15)};
    margin-top: 30px;

    ${mq.lessThan('mobile')} {
      font-size: ${rem(13)};
      margin-top: 15px;
    }
  }

  .link {
    border-bottom: 1px dotted #333;
    display: inline-block;
    font-size: ${rem(14)};
    line-height: 1.25;
    margin-top: 10px;
    word-break: break-all;

    ${mq.lessThan('mobile')} {
      font-size: ${rem(13)};
      margin-top: 10px;
    }

    &._closed {
      pointer-events: none;
      text-decoration: line-through;
    }
  }

  .heading {
    font-size: ${rem(18)};
    font-weight: 600;
    letter-spacing: 0.1rem;
    margin-bottom: 4px;

    ${mq.lessThan('mobile')} {
      font-size: ${rem(15)};
    }
  }

  .role {
    letter-spacing: 0.04rem;
    line-height: 1.6;
    margin-top: 24px;

    ${mq.lessThan('mobile')} {
      font-size: ${rem(13)};
    }

    &_list {
      list-style: none;
    }
  }

  .credit {
    letter-spacing: 0.04rem;
    line-height: 1.6;
    margin-top: 24px;

    ${mq.lessThan('mobile')} {
      font-size: ${rem(13)};
    }

    &_list {
      list-style: none;
    }
  }
`

const Body = styled.div`
  margin-top: 65px;

  @include mq(mobile) {
    margin-top: 45px;
  }

  .row {
    margin-bottom: 62px;

    @include mq(mobileLandscape) {
      margin-bottom: 40px;
    }

    @include mq(mobile) {
      margin-bottom: 25px;
    }

    &._has-column {
      display: flex;
      justify-content: space-between;

      @include mq(mobile) {
        display: block;
      }

      .col:not(:last-of-type) {
        @include mq(mobile) {
          margin-bottom: 25px;
        }
      }

      &._two-column {
        .col {
          width: calc((100% - 62px) / 2);

          @include mq(mobileLandscape) {
            width: calc((100% - 40px) / 2);
          }

          @include mq(mobile) {
            width: 100%;
          }
        }
      }

      &._three-column {
        .col {
          width: calc((100% - 62px * 2) / 3);

          @include mq(mobileLandscape) {
            width: calc((100% - 40px * 2) / 3);
          }

          @include mq(mobile) {
            width: 100%;
          }
        }
      }
    }
  }

  .imgWrapper {
    box-shadow: 0 0 70px -4px #ababab;

    @media (prefers-color-scheme: dark) {
      box-shadow: 0 0 70px -4px #000;
    }
  }

  .imgCaption {
    font-style: italic;
    line-height: 1.3;
    margin-top: 9px;
  }
`

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        url
        siteClosed
        description
        role
        credit
      }
    }
  }
`
type WorksPostProps = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string
        description: string
        url: string
        siteClosed: boolean
        role: string[]
        credit: string[]
      }
      html: string
    }
  }
}

const WorksPost: React.FC<WorksPostProps> = ({ data }: WorksPostProps) => {
  const post = data.markdownRemark

  if (isBrowser() && !isLoggedIn()) {
    navigate('/works/')
    return null
  } else {
    return (
      <Layout>
        <SEO title={`${post.frontmatter.title} | Works`} />
        <Container>
          <Header>
            <h2 className="title">{post.frontmatter.title}</h2>
            <div className="description">
              <p>{post.frontmatter.description}</p>
            </div>
            <a
              href={post.frontmatter.url}
              className={`link ${
                post.frontmatter.siteClosed ? '_closed' : ''
              } `}
              target="_blank"
              rel="noopener noreferrer"
            >
              {post.frontmatter.url}
            </a>
            <div className="role">
              <p className="heading">Role</p>
              <ul className="role_list">
                {post.frontmatter.role.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="credit">
              <p className="heading">Credit</p>
              <ul className="credit_list">
                {post.frontmatter.credit.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
          </Header>
          <Body
            className="body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </Container>
      </Layout>
    )
  }
}

export default WorksPost
